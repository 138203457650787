<template>
  <div>
    <div class="p-2 lg:w-2/5 md:w-2/3 sm:w-full">
      <label
        id="listbox-label"
        class="block text-lg mb-2 font-medium text-gray-700 mt-6"
      >
        Seleccione proyecto a visualizar:
      </label>
    </div>
    <div class="mt-2 w-full">
      <div class="grid grid-col-1 md:grid-cols-3 gap-4">
        <div
          class="h-auto  w-full bg-white p-4 rounded-sm hover:shadow border-0 focus:border-4 focus-visible:border"
          v-for="project in projects"
          :key="project.id"
        >
          <div class="flex justify-center">
            <img
              :src="getCover(project.project_images)"
              class="h-64 w-auto -mt-4"
            />
          </div>
          <div>
            <p class="font-semibold ml-2">{{ project.name }}</p>
          </div>
          <div class="flex justify-center grid-cols-1 mt-4">
            <button
              class="flex justify-center text-xl p-2 bg-blue-200 text-blue-500 w-full"
              @click="openQuotes(project.id)"
            >
              Ver {{ $tc("message.quote", 2, user.countryCode) }}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 ml-4 mt-0.5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import projectsApi from "../../projects/services/project.api";
import { sentryCaptureException } from "../../../helpers/Sentry";

export default {
  components: {
  },
  data() {
    return {
      value: "Seleccionar...",
      selection: "",
      projects: []
    };
  },
  async mounted() {
    try {
      const developerId = this.$store.getters["auth/getUser"].developerId;
      this.projects = await projectsApi.listProjects(developerId);
      this.$store.state.project.breadcrumbs = [{pageName: 'Cotizaciones' , route:'/cotizaciones'}];
    } catch (e) {
      sentryCaptureException(e, "quotes");
    }
  },
  computed: {
    ...mapState("auth", ["user"])
  },
  methods: {
    getCover(images) {
      const cover = images.find(img => img.project_image_gallery === "COVER");
      return cover.img_url;
    },
    openQuotes(projectId) {
      this.$store.dispatch("project/currentProject", { projectId });
      this.$router.push(`/cotizaciones-en-proyecto/${projectId}`);
    }
  }
};
</script>
